<template>
  <div class="app">
    <div class="common-content">
      <el-row>
        <el-col class="test-img">
          <img src="../assets/images/test-img.jpg" alt />
        </el-col>
        <el-col>
          <el-row class="test-title">
            <el-col>
              <h4>{{ detail && detail.title }}</h4>
              <p>
                <span>时间：{{ detail && detail.time || 45 }}分钟</span>
                <span>标签：{{ detail && detail.tag.tag_name || '' }}</span>
              </p>
            </el-col>
          </el-row>
          <el-row v-if="butShow"><el-button type="warning" plain @click="startTest">开始考试</el-button></el-row>
          <el-row v-else>
            <el-col class="form-submit">剩余时间：{{ timeShow }}</el-col>
            <el-col v-for="(ques, key) in detail.questions" :key="key">
              <el-form >
                <el-form-item>
                  <h4>{{ ques.article_name }}</h4>
                </el-form-item>
                <el-form-item v-for="(item,k) in ques.lists" :key="k">
                  <p><i class="item-order">{{ k+1 }}</i><span>{{ item.title }} ({{ item.type === 0 ? '单选题' : '多选题'}} {{ques.score}}分)</span></p>
                  <el-radio-group v-if="item.type === 0" v-model="form[key][k]" size="small">
                    <el-radio v-for="(option, opk) in item.options" :label="option.answer" :key="opk">{{ option.answer }}</el-radio>
                  </el-radio-group>
                  <el-checkbox-group v-if="item.type === 1" v-model="form[key][k]" size="small">
                    <el-checkbox v-for="(option, opk) in item.options" :label="option.answer" :key="opk">{{ option.answer }}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col class="form-submit"><el-button type="warning" plain @click="finishTest">提交</el-button></el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      timer: null,
      minutes: 0,
      timeShow: '',
      detail: null,
      butShow: true,
      index: 0,
      checks: [],
      form: []
    }
  },
  created: function () {},
  methods: {
    timeClock () {
      if (this.minutes <= 0) {
        this.finishTest()
      } else {
        this.minutes--
      }
      this.timeShow = Math.floor(this.minutes / 60) + '分钟' + this.minutes % 60 + '秒'
    },
    startTest () {
      let _this = this
      this.$confirm('考试前请做好准备，一旦开考中途不得退出', '提示', {
        confirmButtonText: '准备好了',
        cancelButtonText: '还没准备好',
        type: 'warning'
      }).then(() => {
        _this.$store.dispatch('startPaper', this.$route.params.id).then(res => {
          _this.timer = setInterval(_this.timeClock, 1000)
          _this.detail = res
          _this.butShow = false
          this.minutes = res.time * 60
          for (let qk in res.questions) {
            let que = []
            for (let lk in res.questions[qk].lists) {
              let val = ''
              if (res.questions[qk].lists[lk].type === 1) {
                val = []
              }
              que.push(val)
            }
            _this.form.push(que)
          }
        }, () => {
          _this.$router.push({ name: 'UserExams' })
        })
      }).catch(() => {
      })
    },
    finishTest () {
      let _this = this
      clearInterval(this.timer)
      this.$store.dispatch('finishPaper', {
        id: _this.$route.params.id,
        answers: _this.form
      }).then((res) => {
        _this.$alert('您本次测评得分' + res.score, '测评结果', {
          confirmButtonText: '确定',
          callback: action => {
            _this.$router.push({ name: 'UserExams' })
          }
        })
      })
    },
    getPaper () {
      let _this = this
      this.$store.dispatch('getPaperInfo', this.$route.params.id).then(res => {
        this.detail = res
      }, () => {
        _this.$router.push({ name: 'UserExams' })
      })
    }
  },
  mounted () {
    this.getPaper()
  }
}
</script>

<style lang="less">
  .app {
    margin: 40px 0;
    .common-content {
      box-shadow: 0 0 30px @global-shadow-color;
      .test-img {
        img {
          width: 100%;
        }
      }
      .test-title {
        h4 {
          margin-bottom: 0;
        }
        p {
          span {
            margin: 0 10px;
          }
        }
      }
      .el-form {
        padding: 0 50px 20px;
        .el-form-item {
          text-align: left;
          .el-form-item__content{
            line-height: 20px
          }
          h4 {
            text-align: left;
            margin: 0;
          }
          .item-order {
            font-style:normal;
            padding: 0 10px;
            font-size: 12px;
            border-radius: 10px;
            margin-right: 5px;
            background: @global-title-p-color;
            color: @global-white;
          }
          p {
            display: inline-block;
            letter-spacing: 2px;
            margin: 0;
            margin-bottom:20px
          }
          .el-radio-group{
            display: block;
            /deep/.el-radio__input.is-checked .el-radio__inner{
              border-color:@global-submit-color;
              background: @global-submit-color;
            }
            /deep/.el-radio__input.is-checked+.el-radio__label{
              color:@global-text-color;
            }
          }
        }
        .form-submit{
          text-align: center;
          .el-button{
            background: @global-submit-color;
            color:@global-text-color;
            border-radius: 20px;
            padding:12px 50px
          }
        }
      }
    }
  }
</style>

<style lang="less">
  .el-message-box {
    width: 100%;
  }
</style>
